<template>
   <div class="box">
      <div class="title">
         <!-- 行左图标与标题 -->
         <el-icon class="el-icon-edit-outline icon"></el-icon>
          <div class="icon-text">
            {{ title  }}
         </div>
        <!-- 行右 -->
         <div v-if="right" class="title-right">
            {{  titleRight }}
         </div>
      </div>
      <!-- 是否下方直接显示文本 -->
      <div class="font" v-if="font" :style="{color: color}">
          {{ info }}
      </div>
       <!-- 下方内容自定义 -->
        <slot name="data"></slot>
      
   </div>
</template>
<script>
export default {
   name: 'MyStrip',
   props: {
      title: {
        type :String,
        required: true
      },
      info: {
        type :String,
       
      },
      font: {
          default: false,
          type: Boolean
      },
      titleRight: String,
      right: {
         default: false,
         type: Boolean
      },
      color: String
   },
   data() {
     return {
       
     }
   },
   computed: {
     
   },
   watch: {
     
   },
   mounted() {
     
   },
   methods: {
     
   }
};
</script>
<style lang='scss' scoped>
.box {
    margin: 10px 0;
    .title {
    display: flex;
    font-size: 14px;
    margin-bottom: 5px;
    .icon {
     color: #008FD7 ;
     margin-right: 5px;
     font-size: 22px;
   }
   .title-right {
      margin-left: 20px;
      color: #008FD7;
   }
}

.font {
    margin-left: 25px;
    font-size: 13px;
    margin-top: 5px;
}

}
</style>