<template>
   <div>
      <el-dialog
         :visible="show"
         :show-close="false"
         :close-on-click-modal="false"
         :width="width"
         :before-close="handleClose">
       <template #title>
         <!-- 通用头部 -->
         <slot name="title">
             <div class="title">
                <span>{{ title }}</span>
                <!-- 弹框标题行右侧内容自定义 -->
                 <div v-if="titleRight">
                     <slot name="titleRight"></slot>
                 </div>
                <el-icon v-else class="el-icon-circle-close close" @click.native="$emit('update:show', false)"></el-icon>
             </div>
             <div class="dashed"></div>
         </slot>
       </template>
       <template>
         <!-- <div>主题内容</div> -->
         <div class="main">
            <slot name="main"></slot>
         </div>
       </template>

       <template #footer>
         <!-- <div>自定义底部</div> -->
          <div class="footer">
            <slot name="footer"></slot>
          </div>
       </template>
      </el-dialog>
   </div>
</template>

<script>
export default {
   name: 'MyDialog',
   props: {
      show: {
         type: Boolean,
         required: true,
      },
      title: {
         type: String,
         required: true
      },
      titleRight: {
         default: false,
         type: Boolean
      },
      width: {
         default: '27%',
         type: String,
      }
   },
   methods: {
      handleClose() {  
    this.$emit('update:show', false);  
    this.$emit('resetData'); // 发出自定义事件来通知父组件重置数据  
  }  
   }
};
</script>
<style lang='scss' scoped>
::v-deep .el-dialog__body {
   font-size: 16px;
   box-sizing: border-box;
}
::v-deep .el-dialog__header {
   background-color: #eee;
   padding-bottom: 0;
}
.title {
   display: flex;
   justify-content: space-between;
   span {
      font-size: 15px;
      font-weight: 550;
   }
}
.main {
   padding: 0 10px;
   max-height: 60vh;
   overflow: auto;
}

.footer {
   display: flex;
   justify-content: space-evenly;
}
.close {
   font-size: 24px;
   cursor: pointer;
}
.dashed {
   margin-top: 15px;
   border-bottom: 1px dashed #BBBBBB ;
}
</style>