export default {
     namespaced: true,
     state: {
        BBMtoken : JSON.parse(localStorage.getItem('user'))?.BBMtoken,
        account: JSON.parse(localStorage.getItem('user'))?.account,
        password: JSON.parse(localStorage.getItem('user'))?.password
     },
     mutations: {
         // 存账号密码
         setUserAccount(state, data) {
            // console.log(state, data);
            state.account = data.account
            state.password = data.password
        },
        // 登录token
        setToken(state,data) {
            state.BBMtoken = data
        },
     },
     actions: {
     },
}