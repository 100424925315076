<template>
    <div class="infoBox">
        <div class="header">
            <!-- 头部 -->
            <slot name="headerData" :value="value"></slot>
        </div>
        <div class="info-main">
            <div class="info-item" v-for="(it, index) in infoList" :key="index">
                <div class="title">
                    {{ it.title }}
                </div>
                <div class="info-detail">
                    <slot :name="it.slot" :value="value"></slot>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'MyInfo',
    props: {
        infoList: {
            type: Array,
            required: true
        },
        value: {
            type: Object
        }
    },
    data() {
        return {

        }
    },
    created() {

    },
    methods: {

    }
};
</script>
<style lang='scss' scoped>
// #F2F2F2 100% #BBBBBB 100%
.infoBox {
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
    margin-top: 10px;
    border-bottom: 1px solid #f2f2f2;
    .header {
        border-bottom: 1px solid #F2F2F2;
        padding: 10px 20px;
        font-size: 14px;
    }

    .info-main {
        display: flex;

        .info-item {
            flex: 0.6;
            display: flex;

            .title {
                width: 18px;
                padding: 5px 8px;
                border-right: 1px solid #F2F2F2;
                border-left: 1px solid #F2F2F2;
                font-size: 14px;
                font-weight: 550;
                text-align: center;
            }


            .info-detail {
                width: 100%;

                // border-right: 1px solid #F2F2F2;
                padding: 5px 5px;

            }
        }

        .info-item:nth-child(4) {
            flex: 1.5;

        }

        .info-item:nth-child(3) {
            flex: 0.4;


        }

        .info-item:last-child {
            flex: .4;
        }

        .info-item:first-child .title {
            border-left: none;
        }
    }
}</style>