<template>
   <div class="bodyBox">
       <span class="header-title" v-for="(it,index)  in title" :key="index" @click="$router.push(it.router)">
            {{ it.title }}&nbsp; <el-icon class="el-icon-arrow-right"></el-icon>
       </span>
       <div>
          <div class="header">
            <el-tabs v-model="activeName" @tab-click="handleClick" v-if="titleList.length > 0" >
                <el-tab-pane :label="it.name" :name="it.id" v-for="(it,index) in titleList" :key="index">
                   <div class="mains" v-if="activeName == index">
                        <slot :name="it.slot"> </slot>
                   </div>
                </el-tab-pane>
            </el-tabs> 
            <div class="left" v-else>
                <slot name="headerLeft"></slot>
            </div>
            <div :class="{ 'right' : titleList.length > 0 }">
                <slot name="headerRight"></slot>
            </div>
          </div>
         <div class="mains" :class="{'mains-gray': mainsGray}" v-if="titleList.length < 1">
              <slot name="main"></slot>
         </div>
       </div>
   </div>
</template>
<script>
export default {
   name: 'MyCard',
   props: {
     title :{
        required: true,
        type: Array
     },
     titleList: {
        type: Array,
        default: () => [],
     },
     mainsGray :{
        type: Boolean,
        default: false
     },
   },
   data() {
     return {
        activeName: '0'
     }
   },
   
   methods: {
    handleClick() {
        this.$emit('handleClick', this.activeName)
      }
   }
};
</script>
<style lang='scss' scoped>

::v-deep .el-tabs--top .el-tabs__item.is-top:last-child, .el-tabs--top .el-tabs__item.is-bottom:last-child, .el-tabs--bottom .el-tabs__item.is-top:last-child, .el-tabs--bottom .el-tabs__item.is-bottom:last-child  {
     padding-right: 20px;
}
::v-deep .el-tabs--top .el-tabs__item.is-top:nth-child(2), .el-tabs--top .el-tabs__item.is-bottom:nth-child(2), .el-tabs--bottom .el-tabs__item.is-top:nth-child(2), .el-tabs--bottom .el-tabs__item.is-bottom:nth-child(2) {
    padding-left: 20px;
}
::v-deep .el-tabs {
    width: 100%;
} 
::v-deep .el-tabs__nav-wrap::after {
    display: none;
   
}
::v-deep .el-tabs__item.is-active {
    color: #000;
    font-weight: 700;
    font-size: 15px;
}

::v-deep .el-tabs__active-bar {
    width: 20px !important;
    height: 5px;
    border-radius: 5px;
    left: 7% ;    // tab栏字体宽度超过或少于四个字时需要重新穿透这个left属性
    // transform: translateX(-50%) ;
}

::v-deep .el-tabs__item {
    padding: 0 20px;
}


.bodyBox {
    width: 100%;
    height: 86vh;
    padding: 10px 10px;
    box-sizing: border-box;

    .header-title {
        cursor: pointer;
        width: auto;
        font-size: 15px;
        font-weight: 550;
    }
    .header {
      position: relative;
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin: 10px 0;
      .left {
         display: flex;
         justify-content: left;

      }
    //   align-items: center;
    .mains {
        width: 100%;
        height: 76vh;
        background-color: #fff;
        border-radius: 10px;
        padding: 8px 12px;
        box-sizing: border-box;
        overflow: auto;
    }
    .right {
        position: absolute;
        right: 0;
        top: 0;
        margin-top: 8px;
    }
}

    .mains {
        width: 100%;
        height: 77vh;
        background-color: #fff;
        border-radius: 10px;
        padding: 8px 12px;
        box-sizing: border-box;
        overflow: auto;
        &-gray{
            background-color: #f7f7f7;
        }
    }
}

</style>