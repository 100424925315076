<template>
	<div>
		<el-table :data="data" ref="singleTable"  :highlight-current-row="colorBlock" @current-change="handleCurrentChange"  @filter-change="change"  :header-cell-style="bgc"
			:border="border" style="width: 100%" @selection-change="changeSel" @row-click="rowClick"
			:row-class-name="tableRowClassName">
			<!-- 通用序号列表, 用serialNumber控制是否生成 -->
			<el-table-column v-if="serialNumber" align="center" label="序号" :min-width="serialMinWidth ? serialMinWidth : '50%'" >
				<template #default="{ $index }">
					{{ $index + 1 }}
				</template>
			</el-table-column>
			<!-- 自定义前面内容插槽 -->
			<slot name="custom"></slot>
			<!-- 自定义数组循环生产table -->
			<el-table-column v-for="(item, index) in tabelList" :key="index" align="center" :width="item.width"   :type="item.type"
				:prop="item.prop" :label="item.name" :min-width="item.minWidth ? item.minWidth : '80'" :filters="item.filters" :filter-multiple="false" >
				<template #default="scoped" v-if="item.slot">
					<slot :name="item.slot" :row="scoped.row"></slot>
				</template>
			</el-table-column>
			<!-- 插槽自定义尾部或者全部内容 -->
			<slot name="tail"></slot>
			<!-- 自定义操作按钮, 通过operation控制生成, 使用插槽operation进行自定义 -->
			<el-table-column label="操作" align="center" v-if="operation"
				:min-width="operationMinWidth ? operationMinWidth : '120'" :width="operationWidth">
				<template #default="scoped">
					<slot name="operation" :scoped="scoped"></slot>
				</template>
			</el-table-column>
		</el-table>
	</div>
</template>
<script>
export default {
	name: 'MyTable',
	props: {
		// 控制操作列
		operation: {
			default: true,
			type: Boolean
		},
		//  控制序号列
		serialNumber: {
			default: true,
			type: Boolean
		},
		// 序号最短宽度
		serialMinWidth: {
			default: "",
			type: String
		},
		colorBlock: { // 色块
			default: false,
			type: Boolean
		},
		bgc: {
			default: () => {},
			type: Function,
		},
		// 样式
		tableRowClassName: {
			default: "",
			type: String
		},
		//  请求的数据
		data: Array,
		// 根据接口手动生成的内容
		tabelList: Array,
		// 操作栏宽度
		operationWidth: {
			default: "",
			type: String
		},
		// 操作栏最小宽度
		operationMinWidth: {
			default: "",
			type: String
		},
		// 边框
		border: {
			default: true,
			type: Boolean
		},
		// 自定义列
		customCol: {
			// default: [],
			default: () => [],
			type: Array,
		},
		
	},
	data() {
		return {
			loading: true
			// loading: false,
		}
	},
	watch: {
		data(newValue) {
			console.log(newValue);
			let i = setTimeout(() => {      // 两秒后自动关闭
				this.loading = false
			}, 2000)
			if (newValue.length > 0) {
				this.loading = false
				clearTimeout(i)
			}
		}
	},
	created() {
		setTimeout(() => {      // 两秒后自动关闭
			this.loading = false
		}, 5000)
	},
	methods: {
		handleCurrentChange(currentRow) {
			if (this.colorBlock) {
				//  console.log(currentRow);
				this.$emit('selected', currentRow)
			}
		},
		filterTag(value, row) {
			return row.tag === value;
		},
		filterHandler(value, row, column) {
			const property = column['property'];
			return row[property] === value;
		},
		changeSel(val) {
			this.$emit('handleSelectionChange', val)
		},
		row(e, rowIndex) {
			console.log(e, rowIndex);
		},
		// 修改下拉属性
		change(value) {
            this.$emit('optionChange', value)
		},
		rowClick(e) {
			// console.log(e);
			this.$emit('rowClick', e)
		},
	}
};
</script>
<style lang='scss' scoped>
::v-deep .el-table .cell p {
	line-height: 16px;
	margin: 8px 0;
}


::v-deep .el-loading-spinner .path {
	stroke: #008FD7;
}
// ::v-deep .el-table--enable-row-transition .el-table__body td.el-table__cell {
// 	border-bottom: 3px solid #e8e8e8;
// }
::v-deep .el-icon-arrow-down:before {
    font-size: 14px;
    margin-left: 5px;
	color: #000;
 }
::v-deep .el-table {
	font-size: 14px;
    position: static
}

::v-deep .el-table--group, .el-table--border {
	border: none;
}

::v-deep .el-table--border .el-table__cell, .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
	border-right: none;
}
::v-deep .el-table .bgc-row {
	background: #F5F8FF;
}

.header-row {
	border-radius: 10px;
	background-color: #000;
}
</style>