<template>
   <div style="margin-top: 0.1vw; text-align: right;">
             <el-pagination
                  @size-change="changeSizes"
                  @current-change="changeCurrent"
                  @prev-click="prev"
                  @next-click="next"
                  @keyup.enter="submit"
                
                  :current-page="current"
                  :page-size="pageSize"
                  :page-sizes="[5, 10, 20]"
                  layout="total, prev, pager, next"
                  :total="total">
                  
               </el-pagination>
         </div>
</template>
<script>
export default {
   name: 'MyPagination',
   props: {
      total: {
      required: true
     },
     current: {
      required: true
     },
     pageSize: {
      required: true
     }
   },
   methods: {
      // 切换最大页数
      changeSizes(e) {
          this.$emit('changeSize', e)
      },
      // 切换当前页
      changeCurrent(e) {
         this.$emit('changeCurrent', e)
      },
      // 上一页
      prev(e) {
         this.$emit('prev', e)
      },
      // 下一页
      next(e) {
         this.$emit('next', e)
      },
      // 确定按钮
      submit(e) {
         this.$emit('submit', e)
      },
   }
};
</script>
<style lang='scss' scoped>
@mixin hi() {
   height: 35px;
}

// ::v-deep .el-pagination.is-background .btn-prev{
//     height: 30px;
//     border: 1px solid #c0c4cc;
// }
// ::v-deep  .el-pagination.is-background .btn-next  {
//     height: 30px;
//     border: 1px solid #c0c4cc;
// }
// ::v-deep .el-pagination__editor.el-input .el-input__inner {
//    height: 30px;
// }
// ::v-deep .el-input__inner:focus  {
//    border-color:#16C2B2 ;
// }
// ::v-deep .el-pager li {
//    min-width: 30px !important;
//    height: 30px;
//    line-height: 30px;
// }
// ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
//    background-color: #16C2B2;
// }


// ::v-deep .el-pagination span:not([class*=suffix]), .el-pagination button {
//    font-size: 14px;
// }
</style>