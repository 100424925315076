import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { message } from '@/utils/message'
import Element from 'element-ui'

import '@/css/element-variables.scss'
import '@/css/my-css.scss'
Vue.use(Element)

import MyCard from '@/components/MyCard'
import MyTable from './components/MyTable.vue'
import MyPagination from './components/MyPagination.vue'
import MyDialog from  './components/MyDialog.vue'
import MyInfo from  './components/MyInfo.vue'
import MyStrip from  './components/MyStrip.vue'
import 'element-ui/lib/theme-chalk/base.css';
if(localStorage.getItem('user')){
  var token=JSON.parse(localStorage.getItem('user')).user.BBMtoken
}else{
  var token=null
}

console.log(token,'sss');
const white=['login']
router.beforeEach((to, from, next) => {
  var token=JSON.parse(localStorage.getItem('user'))?.user.BBMtoken
  if (!token && !white.includes(to.name)) {

      next({ name: 'login' })
      message('请先登录', 'warning')
  } else if (token && to.name === 'login') {
      next({ name: 'layout' })
  } else (
      next()
  )
})
// collapse 展开折叠
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';
Vue.component('MyStrip', MyStrip)
Vue.component(CollapseTransition.name, CollapseTransition)
Vue.component('MyInfo', MyInfo)
Vue.component('MyCard', MyCard)
Vue.component('MyDialog', MyDialog)
Vue.component('MyTable', MyTable)
Vue.component('MyPagination',MyPagination)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
