import Vue from 'vue'
import VueRouter from 'vue-router'

// const originalPush = VueRouter.prototype.push;
// VueRouter.prototype.push = function push(location, onResolve, onReject) {
//   if (onResolve || onReject)
//     return originalPush.call(this, location, onResolve, onReject);
//   return originalPush.call(this, location).catch((err) => err);
// }


Vue.use(VueRouter)

const routes = [
  // 登录页
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index')
  },
  // 首页
  {
    path: '/',
    name: 'layout',
    redirect: 'workbench',
    component: () => import('@/views/layout/index'),
    children: [
      // 工作台
       {
        path: '/workbench',
        name: 'workbench',
        component: () => import('@/views/pages/workbench/index'),
       },
        // 基本配置-权限
        {
          path: '/permissionManagement',
          name: 'permissionManagement',
          component: () => import('@/views/pages/basicConfiguration/permissionManagement/index'),
         },
       // 基本配置-支付
       {
        path: '/paymentConfiguration',
        name: 'paymentConfiguration',
        component: () => import('@/views/pages/basicConfiguration/paymentConfiguration/index'),
       },
        // 基本配置-审核价格
        {
          path: '/reviewPrices',
          name: 'reviewPrices',
          component: () => import('@/views/pages/basicConfiguration/reviewPrices/index'),
         },
        // 基本配置-协议
        {
          path: '/protocolConfiguration',
          name: 'protocolConfiguration',
          component: () => import('@/views/pages/basicConfiguration/protocolConfiguration/index'),
         },
       // 病例管理
       {
        path: '/caseManagement',
        name: 'caseManagement',
        component: () => import('@/views/pages/caseManagement/index'),
       },
       // 客户管理
       {
        path: '/customerManagement',
        name: 'customerManagement',
        component: () => import('@/views/pages/customerManagement/index'),
       },
       // 人员管理-专家
       {
        path: '/expertPersonnel',
        name: 'expertPersonnel',
        component: () => import('@/views/pages/personnelManagement/expertPersonnel/index'),
       },
        // 人员管理-专业
        {
          path: '/professionalPersonnel',
          name: 'professionalPersonnel',
          component: () => import('@/views/pages/personnelManagement/professionalPersonnel/index'),
         },
          // 人员管理-业务
       {
        path: '/businessPersonnel',
        name: 'businessPersonnel',
        component: () => import('@/views/pages/personnelManagement/businessPersonnel/index'),
       },
       // 汇报打卡
      //  打卡记录
       {
        path: '/clockInRecords',
        name: 'clockInRecords',
        component: () => import('@/views/pages/reportClockIn/clockInRecords/index'),
       },
        //  工作内容
        {
          path: '/jobResponsibilities',
          name: 'jobResponsibilities',
          component: () => import('@/views/pages/reportClockIn/jobResponsibilities/index'),
         },
        //  工作汇报
        {
          path: '/workReport',
          name: 'workReport',
          component: () => import('@/views/pages/reportClockIn/workReport/index'),
         },
       // 论坛管理
       {
        path: '/forumManagement',
        name: 'forumManagement',
        component: () => import('@/views/pages/forumManagement/index'),
       },
        // 生产中心-订单
        {
          path: '/orderManagement',
          name: 'orderManagement',
          component: () => import('@/views/pages/generationCenter/orderManagement/index'),
         },
           // 生产中心-生产单位
        {
          path: '/processingUnitAssociation',
          name: 'processingUnitAssociation',
          component: () => import('@/views/pages/generationCenter/processingUnitAssociation/index'),
         },
           // 生产中心-生产内容
        {
          path: '/processingContentManagement',
          name: 'processingContentManagement',
          component: () => import('@/views/pages/generationCenter/processingContentManagement/index'),
         },
        // 审核管理
        {
          path: '/auditManagement',
          name: 'auditManagement',
          component: () => import('@/views/pages/auditManagement/index'),
         },
          // 新增角色
        {
          path: '/addRole',
          name: 'addRole',
          component: () => import('@/views/routerPages/addRole'),
         },
            // 新增权限
        {
          path: '/addAuthority',
          name: 'addAuthority',
          component: () => import('@/views/routerPages/addAuthority'),
         },
            // 客户管理-详情/编辑
        {
          path: '/customerDetails',
          name: 'customerDetails',
          component: () => import('@/views/routerPages/customerDetails'),
         },
              // 订单详情
        {
          path: '/orderDetail',
          name: 'orderDetail',
          component: () => import('@/views/routerPages/orderDetail'),
         },
               // 审核详情
        {
          path: '/auditingDetail',
          name: 'auditingDetail',
          component: () => import('@/views/routerPages/auditingDetail'),
         },
                 // 病例详情
        {
          path: '/caseDetail',
          name: 'caseDetail',
          component: () => import('@/views/routerPages/caseDetail'),
         },
         //论坛详情
         {
          path: '/fornumInfo',
          name: 'fornumInfo',
          component: () => import('@/views/routerPages/fornumInfo.vue')
        },
        // 图片详情
        // {
        //   path: '/picturesDetail',
        //   name: 'picturesDetail',
        //   component: () => import('@/views/routerPages/picturesPage/picturesDetail.vue')
        // },
        // 头颅侧位定位片
        // {
        //   path: '/LateralPosition',
        //   name: 'LateralPosition',
        //   component: () => import('@/views/routerPages/picturesPage/LateralPosition.vue')
        // },
        // // 正面像
        // {
        //   path: '/theRightSide',
        //   name: 'theRightSide',
        //   component: () => import('@/views/routerPages/picturesPage/theRightSide.vue')
        // },
        // // 侧面像
        // {
        //   path: '/profileView',
        //   name: 'profileView',
        //   component: () => import('@/views/routerPages/picturesPage/profileView.vue')
        // },
        // // 正面微笑像
        {
          path: '/smilingImage',
          name: 'smilingImage',
          component: () => import('@/views/routerPages/picturesPage/smilingImage.vue')
        },
        // // 正面咬合像
        // {
        //   path: '/frontalOcclusion',
        //   name: 'frontalOcclusion',
        //   component: () => import('@/views/routerPages/picturesPage/frontalOcclusion.vue')
        // },
        // // 45度像
        // {
        //   path: '/fortyfiveImages',
        //   name: 'fortyfiveImages',
        //   component: () => import('@/views/routerPages/picturesPage/fortyfiveImages.vue')
        // },
        // // 左侧咬合
        // {
        //   path: '/rightImage',
        //   name: 'rightImage',
        //   component: () => import('@/views/routerPages/picturesPage/rightImage.vue')
        // },
        //  // 右侧咬合
        //  {
        //   path: '/leftImage',
        //   name: 'leftImage',
        //   component: () => import('@/views/routerPages/picturesPage/leftImage.vue')
        // },
        // // 上牙弓像
        //  {
        //   path: '/upImage',
        //   name: 'upImage',
        //   component: () => import('@/views/routerPages/picturesPage/upImage.vue')
        // },
        //  // 下牙弓像
        //  {
        //   path: '/dowmImage',
        //   name: 'dowmImage',
        //   component: () => import('@/views/routerPages/picturesPage/dowmImage.vue')
        // },
        //  // 上颌模型
        //  {
        //   path: '/maxillaryImage',
        //   name: 'maxillaryImage',
        //   component: () => import('@/views/routerPages/picturesPage/maxillaryImage.vue')
        // },
        //  // 下颌模型
        //  {
        //   path: '/mandibularImage',
        //   name: 'mandibularImage',
        //   component: () => import('@/views/routerPages/picturesPage/mandibularImage.vue')
        // },
    ]
  }

]

const router = new VueRouter({
  routes
})

export default router